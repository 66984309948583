const theme = {
    colors: {
      primary: "#00a1b1",
      text: "#878787",
      white: "#FFFFFF",
    },
    typography: {
      primary: "Raleway",
    },
  };
  
  export default theme;
  