import styled from "styled-components";

import BackgroundBanner from "../../assets/banner-home.jpg";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  padding-top: 90px;
`;

export const Banner = styled.section`
  background-image: url(${BackgroundBanner});
  height: 450px;
`;

export const ContainerFullConsultancy = styled.section`
  height: 715px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 975px) {
    height: 100%;
  }
`;

export const FullConsultancy = styled.div`
  height: 100%;
  width: 1140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1170px) {
    padding: 20px 20px;
    width: 100%;
  }
`;

export const ContentTextConsultancy = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 36px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.typography.primary};
  font-weight: 900;
  @media (max-width: 445px) {
    font-size: 30px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.typography.primary};
  font-weight: 900;
`;

export const ContainerSteps = styled.figure`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

export const ImageSteps = styled.img`
  width: 965px;
  height: 438px;
  @media (max-width: 1010px) {
    width: 100%;
    height: 100%;
  }
`;
