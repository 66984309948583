import React, { forwardRef } from 'react';
import { Button } from './styles';


const ButtonForm = forwardRef(({ children, onClick, type }, ref) => {
  return (
    <Button ref={ref} onClick={onClick} type={type}>
      {children}
    </Button>
  );
});

export default ButtonForm;
