import { NavLink } from "react-router-dom";

import { FaTimes } from "react-icons/fa";

import { Container, Content } from "./styles";

import SidebarItem from "./sideBarItem";

const SideBar = ({ activeDropDown }) => {
  const closeSideBar = () => {
    activeDropDown(false);
  };

  return (
    <Container sidebar={activeDropDown ? "true" : "false"}>
      <FaTimes onClick={closeSideBar} color="black" />
      <Content>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={"HOME"} />
        </NavLink>
        <NavLink to={"/quem-somos"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={"QUEM SOMOS"} />
        </NavLink>
        <NavLink to={"/assessoria"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={"ASSESSORIA COMPLETA"} />
        </NavLink>
        <NavLink to={"/contato"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={"CONTATO"} />
        </NavLink>
      </Content>
    </Container>
  );
};

export default SideBar;
