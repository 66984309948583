import styled from "styled-components";

export const Container = styled.main`
  height: 100%;
  width: 100%;
  padding-top: 90px;
`;

export const ContentContact = styled.section`
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 735px) {
    height: 100%;
  }
`;

export const ContactRealEstate = styled.div`
  width: 1140px;
  display: flex;
  height: 100%;
  flex-direction: column;
  @media (max-width: 1215px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const InformationsContact = styled.div`
  margin-top: 10px;
`;

export const Title = styled.h1`
  font-size: 36px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.typography.primary};
  font-weight: 300;
  margin-bottom: 15px;
`;

export const OfficeInformations = styled.div`
  height: 210px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 735px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const ContentImage = styled.figure`
  margin-top: 10px;
`;

export const ImageContact = styled.img`
  height: 210px;
  width: 350px;
  border-radius: 4px;
  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }
`;

export const OfficeAdress = styled.div`
  height: 100%;
  width: 760px;
  @media (max-width: 735px) {
    width: 100%;
  }
`;

export const OfficeWrite = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  padding: 7px;
`;

export const Office = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
  font-family: "lato", sans-serif;
  font-weight: 500;
`;

export const Social = styled.figure`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a:hover{
    :nth-child(1){
      color: ${({theme}) => theme.colors.primary}
    }
    :nth-child(2){
      color: ${({theme}) => theme.colors.primary}
    }
  }
`;

export const ContactInformations = styled.div`
  height: 150px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 1154px) {
    padding-left: 10px;
  }
  @media (max-width: 735px) {
    height: 100%;
    gap: 10px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
`;

export const TextInformation = styled.label`
  color: #768082;
  font-size: 13px;
  font-family: ${({ theme }) => theme.typography.primary};
`;

export const FormSendContact = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PrimaryFields = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
`;

export const MessageContainer = styled.div`
  height: 200px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;


export const ContentInput = styled.div`
  height: 52px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;