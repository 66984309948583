import Footer from "../../components/footer";
import Header from "../../components/header";

import {
  Container,
  ContainerWhoUs,
  Content,
  Title,
  Subtitle,
  ContentTitle,
  DescriptionsContent,
  TopicsContainer,
  CarouselContainer,
  List,
  Item,
  ContentCarousel,
  ImageCarousel,
} from "./styles";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import RouteInformation from "../../components/routeInformation";

import OneImage from "../../assets/oneImageCarousel.png";
import TwoImage from "../../assets/twoImageCarousel.jpg";
import ThreeImage from "../../assets/threeImageCarousel.jpg";
import ForImage from "../../assets/forImageCarousel.jpg";
import FiveImage from "../../assets/fiveImageCarousel.jpg";

const WhoUs = () => {
  return (
    <Container>
      <Header />
      <ContainerWhoUs>
        <Content>
          <RouteInformation routeCurrent="Quem Somos" />
          <ContentTitle>
            <Title>Sobre a RealEstate4.me</Title>
            <Subtitle>Uma empresa do grupo YMK</Subtitle>
          </ContentTitle>
          <DescriptionsContent>
            <TopicsContainer>
              <List>
                <Item>
                  Imóveis novos nos EUA (lançamentos e prontos) e Europa
                  (Portugal e Espanha)
                </Item>
                <Item>
                  Grande foco no mercado da Flórida (Miami e Orlando), que,
                  estatisticamente, tem maior atratividade para o público
                  brasileiro
                </Item>
                <Item>
                  <strong>Nosso diferencial:</strong> Assessoramos de
                  ponta-a-ponta o comprador Brasileiro, a saber: Definição do
                  perfil de interesse, escolha do produto (imóvel), negociação,
                  fechamento, remessa de divisas, aprovação de financiamento,
                  estruturação tributária (abertura de PJ), entrega do imóvel,
                  administração pós compra para rentabilização do imóvel com
                  locação e até decoração.
                </Item>
                <Item>
                  <strong>Triple Net:</strong> investimento em imóveis
                  comerciais com contrato de locação de longa duração,
                  rentabilidade de 6,5% a 9,5% líquida ao ano.
                </Item>
                <Item>
                  <strong>Assessoria com visto EB-5</strong> –
                  www.greencardparabrasileiros.com – Parceria firmada com o
                  American Regional Center.
                </Item>
                <Item>
                  <strong>Incorporação nos EUA</strong> – Fazemos toda a
                  assessoria local: terreno, projeto, aprovação, construção,
                  marketing e vendas.
                </Item>
                <Item>
                  100% de acesso ao MLS, portanto, aptos também a vender
                  qualquer imóvel de terceiro nos EUA
                </Item>
              </List>
            </TopicsContainer>
            <CarouselContainer>
              <Carousel showThumbs={false} width="342px">
                <ContentCarousel>
                  <ImageCarousel src={OneImage} />
                </ContentCarousel>
                <ContentCarousel>
                  <ImageCarousel src={TwoImage} />
                </ContentCarousel>
                <ContentCarousel>
                  <ImageCarousel src={ThreeImage} />
                </ContentCarousel>
                <ContentCarousel>
                  <ImageCarousel src={ForImage} />
                </ContentCarousel>
                <ContentCarousel>
                  <ImageCarousel src={FiveImage} />
                </ContentCarousel>
              </Carousel>
            </CarouselContainer>
          </DescriptionsContent>
        </Content>
      </ContainerWhoUs>
      <Footer />
    </Container>
  );
};

export default WhoUs;
