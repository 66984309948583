import styled from "styled-components";

export const Content = styled.main`
  width: 100%;
  height: 100%;
  padding-top: 90px;
`;

export const ContainerIframe = styled.section`
  width: 100%;
  height: 100%;
`;
