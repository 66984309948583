import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import Home from "./home";
import WhoUs from "./whoUs";
import Consultancy from "./consultancy";
import Contact from "./contact";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Root = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<WhoUs />} path="/quem-somos" />
        <Route element={<Consultancy />} path="/assessoria" />
        <Route element={<Contact />} path="/contato" />
      </Routes>
    </Router>
  );
};

export default Root;
