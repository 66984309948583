import {
  Container,
  Banner,
  FullConsultancy,
  ContainerFullConsultancy,
  Title,
  Subtitle,
  ContentTextConsultancy,
  ContainerSteps,
  ImageSteps,
} from "./styles";

import ImageStepRealEstate from "../../assets/steps-realestate.png";

import Footer from "../../components/footer";
import Header from "../../components/header";
import RouteInformation from "../../components/routeInformation";

const Consultancy = () => {
  return (
    <Container>
      <Header />
      <Banner />
      <ContainerFullConsultancy>
        <FullConsultancy>
          <RouteInformation routeCurrent="Assesoria Completa" />
          <ContentTextConsultancy>
            <Title>ASSESORIA COMPLETA</Title>
            <Subtitle>CONHEÇA TODOS OS PASSOS DO PROCESSO:</Subtitle>
            <ContainerSteps>
              <ImageSteps src={ImageStepRealEstate} />
            </ContainerSteps>
          </ContentTextConsultancy>
        </FullConsultancy>
      </ContainerFullConsultancy>
      <Footer />
    </Container>
  );
};

export default Consultancy;
