import styled from "styled-components";

export const Container = styled.footer`
  height: 500px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 960px){
    height: 100%;
  }
`;

export const Enterprises = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  @media(max-width: 960px){
    height: 100%;
  }
`;

export const ContentYMK = styled.figure`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Redirect = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const YMKgroup = styled.img`
  width: 150px;
  height: 150px;
`;

export const ListEnterprise = styled.div`
  max-width: 100%;
  width: 100%;
  height: 65px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  @media(max-width: 1050px){
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    height: 100%;
    justify-items: center;
  }
  @media(max-width: 512px){

    grid-template-columns: repeat(2, 4fr);
  }
`;

export const Icon = styled.img`
  width: 80px;
  height: 80px;

  @media(max-width: 960px){
    width: 100px;
    height: 60px;

  }
  @media(max-width: 400px){
    width: 120px;
  }
`;

export const AddresContact = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  @media(max-width:960px){
    width: 100%;
    padding: 20px 20px;
    text-align: center;
  }
  @media(max-width: 550px){
    flex-direction: column;
    height: 100%;
  }

`;

export const ContentIcon = styled.figure`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media(max-width: 400px){
    width: 100%;
  }
`;

export const Info = styled.label`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.primary};
`;

export const SocialMedia = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  margin-top: 20px;

  @media(max-width: 400px){
    width: 100%;
    padding: 20px 20px;
  }
`;

export const Link = styled.a``;

export const Copright = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const TextCopright = styled.label`
  font-size: 12px;
  color: #d0d0d0;
  font-family: ${({ theme }) => theme.typography.primary};

  @media(max-width: 400px){
    width: 100%;
    padding: 20px 20px;
    text-align: center;
  }
`;
