import { useState } from "react";

import SideBar from "../sideBar";

import {
  Container,
  ContentLogo,
  Logo,
  Navigation,
  List,
  Item,
  Content,
  SideBarContainer,
} from "./styles";

import realestate from "../../assets/icons/svg/logo-realestate_color.svg";
import { FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [sideBarShow, setSideBarShow] = useState(false);

  const showSideBar = () => {
    setSideBarShow(!sideBarShow);
  };

  const Refresh = () => {
    const local = useLocation();
    if (local.pathname === "/") {
      window.location.reload();
    } else {
      return;
    }
  };

  return (
    <Content>
      <Container>
        <SideBarContainer>
          <FaBars onClick={showSideBar} size={35} className="SideBar" />
          {sideBarShow && <SideBar activeDropDown={setSideBarShow} />}
        </SideBarContainer>
        <ContentLogo to="/" onClick={Refresh}>
          <Logo src={realestate} />
        </ContentLogo>

        <Navigation>
          <List>
            <Item to="/" onClick={Refresh}>
              HOME
            </Item>
            <Item to="/quem-somos">QUEM SOMOS</Item>
            <Item to="/assessoria">ASSESORIA COMPLETA</Item>
            <Item to="/contato">CONTATO</Item>
          </List>
        </Navigation>
      </Container>
    </Content>
  );
};

export default Header;
