import styled from "styled-components";

export const Container = styled.div`
  color: black;
  display: flex;
  align-items: center;
  font-size: 17px;
  padding: 10px;
  margin: 10px 10px;
  font-family: ${({ theme }) => theme.typography.primary};
  font-weight: bold;
  > svg {
    margin: 0 8px;
  }
`;
