import {
  Container,
  Enterprises,
  ContentYMK,
  YMKgroup,
  Redirect,
  ListEnterprise,
  Icon,
  AddresContact,
  Info,
  ContentIcon,
  SocialMedia,
  Link,
  Copright,
  TextCopright,
} from "./styles";

import ymkLogo from "../../assets/icons/svg/LOGO_YMK_GRUPO_curvas.svg";
import bladoom from "../../assets/icons/svg/logo-bladoom.svg";
import realty from "../../assets/icons/svg/logo_YMK_realty.svg";
import credito from "../../assets/icons/svg/credimov.svg";
import corp from "../../assets/icons/svg/logo_YMK_corp.svg";
import pra from "../../assets/icons/svg/LOGO_IMÓVELPRA.svg";
import land from "../../assets/icons/svg/LOGO_YMK_Land.svg";
import investimov from "../../assets/icons/svg/logo_investimov.svg";
import realestate from "../../assets/icons/svg/realestate_white.svg";
import estoque from "../../assets/icons/svg/logo estoqueimoveis_vetor.svg";

import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLogoYoutube } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaPinterestSquare } from "react-icons/fa";

const Footer = () => {
  const dateCurrent = new Date();
  const yearCurrent = dateCurrent.getFullYear();

  return (
    <Container>
      <Enterprises>
        <ContentYMK>
          <Redirect href="https://ymk.com.br" target="__blank">
            <YMKgroup src={ymkLogo} alt="logo_ymk" />
          </Redirect>
        </ContentYMK>
        <ListEnterprise>
          <Redirect href="https://bladoom.com.br" target="__blank">
            <Icon src={bladoom} alt="logo_bladoom" />
          </Redirect>
          <Redirect href="https://www.ymkrealty.com.br/" target="__blank">
            <Icon src={realty} alt="logo_realty" />
          </Redirect>
          <Redirect href="https://123credito.com.br/" target="__blank">
            <Icon src={credito} alt="logo_123credito" />
          </Redirect>
          <Redirect href="https://www.ymkcorp.com.br/" target="__blank">
            <Icon src={corp} alt="logo_corp" />
          </Redirect>
          <Redirect href="https://www.imovelpra.com.br/" target="__blank">
            <Icon src={pra} alt="logo_imovelpra" />
          </Redirect>
          <Redirect href="https://www.ymkland.com.br/" target="__blank">
            <Icon src={land} alt="logo_land" />
          </Redirect>
          <Redirect
            href="https://web.facebook.com/people/Investimov/100063454340818/"
            target="__blank"
          >
            <Icon src={investimov} alt="logo_investimov" />
          </Redirect>
          <Redirect href="https://realestate4me.com.br/" target="__blank">
            <Icon src={realestate} alt="logo_realestate" />
          </Redirect>
          <Redirect href="https://www.estoqueimovel.com.br/" target="__blank">
            <Icon src={estoque} alt="logo_estoque" />
          </Redirect>
        </ListEnterprise>
        <AddresContact>
          <ContentIcon>
            <FaPhoneAlt color="white" />
            <Info>+55 (11) 3995-7808</Info>
          </ContentIcon>
          <ContentIcon>
            <MdEmail color="white" />
            <Info>contato@realestate4me.com.br</Info>
          </ContentIcon>
        </AddresContact>
        <SocialMedia>
          <Link
            href="https://www.youtube.com/channel/UCJBPyDnrdJizrdyKMpDuEhQ"
            target="__blank"
          >
            <IoLogoYoutube color="white" size={30} />
          </Link>

          <Link href="https://www.facebook.com/RE4ME?fref=tsl" target="__blank">
            <FaFacebookSquare color="white" size={30} />
          </Link>

          <Link
            href="https://www.instagram.com/realestate4.me/?hl=pt-br"
            target="__blank"
          >
            <FaInstagram color="white" size={30} />
          </Link>
          <Link
            href="https://www.linkedin.com/company/grupoymk/"
            target="__blank"
          >
            <FaLinkedin color="white" size={30} />
          </Link>
          <Link href="https://br.pinterest.com/grupoymk/" target="__blank">
            <FaPinterestSquare color="white" size={30} />
          </Link>
        </SocialMedia>
        <AddresContact>
          <Info>
            Praça Santo Agostinho,70, Aclimação, São Paulo - Brasil | 18
            Pininsula center - Roling Hills Estates, CA 90274 - USA
          </Info>
        </AddresContact>
        <Copright>
          <TextCopright>
            RealEstate4me © {yearCurrent} - Todos os direitos reservados
          </TextCopright>
        </Copright>
      </Enterprises>
    </Container>
  );
};

export default Footer;

//
