import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  padding-top: 90px;
`;

export const ContainerWhoUs = styled.section`
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1180px) {
    padding: 20px 20px;
  }
  @media (max-width: 920px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 1140px;
  height: 100%;
`;

export const ContentTitle = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.primary};
  font-size: 24px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Subtitle = styled.label`
  font-family: ${({ theme }) => theme.typography.primary};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
`;

export const DescriptionsContent = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 920px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const TopicsContainer = styled.div`
  width: 60%;
  height: 100%;
  @media (max-width: 920px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const CarouselContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const List = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 95%;
  @media (max-width: 920px) {
    width: 100%;
    gap: 20px;
  }
`;

export const Item = styled.li`
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.primary};
  color: ${({ theme }) => theme.colors.text};
`;

export const ContentCarousel = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  zoom: 1;
`;

export const ImageCarousel = styled.img`
  width: 342px;
  height: 342px;
`;
