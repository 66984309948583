import styled from "styled-components";


export const Button = styled.button`
    border: none;
    border-radius: 3px;
    background-color: ${({theme}) => theme.colors.primary};
    display: flex;
    width: 145px;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-bottom: 3px solid #007893;
    cursor: pointer;
    font-size: 14px;
    font-family: ${({theme}) => theme.typography.primary};
    color: ${({theme}) => theme.colors.white};
`;
