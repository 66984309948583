import styled from "styled-components";


export const InputField = styled.input`
    height: 100%;
    width: 100%;
    padding-left: 10px;
    border: 1px solid #f0f0f0;
    background-color: ${({theme}) => theme.colors.white};
    border-radius: 3px;
    font-size: 13px;
    border-bottom: 2px solid #768082;
    outline: 0;
    color: #768082;
`;