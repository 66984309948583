import styled from "styled-components";

import { NavLink } from "react-router-dom";

export const Content = styled.header`
  width: 100%;
  border-bottom: 0.5px solid #f0f0f0;
  position: fixed;
  top: 0px;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const SideBarContainer = styled.header`
  display: none;
  @media (max-width: 520px) {
    display: block;
    position: absolute;
    right: 0px;
    margin-right: 20px;
  }
`;

export const Container = styled.div`
  height: 90px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1150px) {
    padding: 20px 20px;
    width: 100%;
  }
  @media (max-width: 810px) {
    flex-direction: column;
    height: 100%;
    gap: 10px;
  }
`;

export const ContentLogo = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

export const Navigation = styled.nav`
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  @media (max-width: 520px) {
    display: none;
  }
`;

export const List = styled.ul`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 880px) {
    gap: 15px;
  }
`;

export const Item = styled(NavLink)`
  font-size: 17px;
  font-family: ${({ theme }) => theme.typography.primary};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
