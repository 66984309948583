import { NavLink } from "react-router-dom";
import { Info, Route } from "./styles";
import { useTheme } from "styled-components";

const RouteInformation = ({ routeCurrent }) => {
  const theme = useTheme()

  return (
    <Route>
      <Info>
        <NavLink to='/' style={{textDecoration: 'none', color: theme.colors.text}}>Início</NavLink> &gt; <label style={{ color: "#00a1b1" }}>{routeCurrent}</label>
      </Info>
    </Route>
  );
};

export default RouteInformation;
