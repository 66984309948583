import styled from "styled-components";

export const Route = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const Info = styled.label`
  color: #99a3b1;
  font-family: ${({ theme }) => theme.typography.primary};
  font-size: 12px;
`;
