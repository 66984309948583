import React, { useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Content, ContainerIframe } from "./styles";

const Home = () => {
  useEffect(() => {
    // Cria o script
    const script = document.createElement("script");
    script.src = "https://assets.newestateonly.com/iframe-loader/load.js";
    script.async = true;
    script.setAttribute("data-neokey", "65bb60b46e952534aa909262");
    script.setAttribute("data-neolang", "pt");

    // Adiciona o script ao body do documento
    document.body.appendChild(script);

    // Remove o script ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Content>
      <Header />
      <ContainerIframe>
        <iframe
          id="NEOiframe"
          style={{ width: "100%", height: "100vh", border: "none" }}
          title="Imóveis"
        ></iframe>
      </ContainerIframe>
      <Footer />
    </Content>
  );
};

export default Home;
