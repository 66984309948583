import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";

import Footer from "../../components/footer";
import Header from "../../components/header";
import RouteInformation from "../../components/routeInformation";
import ButtonForm from "../../components/button";
import Input from "../../components/input";

import {
  Container,
  ContactRealEstate,
  ContentContact,
  InformationsContact,
  Title,
  OfficeInformations,
  ContentImage,
  ImageContact,
  OfficeAdress,
  OfficeWrite,
  Office,
  Social,
  ContactInformations,
  Content,
  TextInformation,
  FormSendContact,
  PrimaryFields,
  MessageContainer,
  ContentInput,
} from "./styles";

import ContactImage from "../../assets/image-contact.jpg";
import { FaFacebookF, FaPhoneAlt, FaHome } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import { GiSmartphone } from "react-icons/gi";
import { CiMail } from "react-icons/ci";

const Contact = () => {
  const theme = useTheme();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const url = "https://realestate4me.com.br/api/sendEmail.php";

  const onSubmit = (data) => {
    console.log(data);

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.success === false) {
          alert(responseData.message);
        } else {
          alert(responseData.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

    reset();
  };

  return (
    <Container>
      <Header />
      <ContentContact>
        <ContactRealEstate>
          <RouteInformation routeCurrent="Contato" />
          <InformationsContact>
            <Title>Contato</Title>
            <OfficeInformations>
              <ContentImage>
                <ImageContact src={ContactImage} />
              </ContentImage>
              <OfficeAdress>
                <OfficeWrite>
                  <Office>ESCRITÓRIO SÃO PAULO - BRASIL:</Office>
                  <Social>
                    <a href="https://web.facebook.com/RE4ME" target="__blank">
                      <FaFacebookF color="#D6D6D6" size={15} />
                    </a>
                    <a href="http://goo.gl/MbevIy" target="__blank">
                      <TiSocialLinkedin color="#D6D6D6" size={20} />
                    </a>
                  </Social>
                </OfficeWrite>

                <ContactInformations>
                  <Content>
                    <FaPhoneAlt color={theme.colors.text} size={13} />
                    <TextInformation>+55 11 3995-7808</TextInformation>
                  </Content>
                  <Content>
                    <GiSmartphone color={theme.colors.text} size={13} />
                    <TextInformation>+55 11 94060-8550</TextInformation>
                  </Content>
                  <Content>
                    <CiMail color={theme.colors.text} size={13} />
                    <TextInformation>
                      contato@realestate4me.com.br
                    </TextInformation>
                  </Content>
                  <Content>
                    <FaHome color={theme.colors.text} size={13} />
                    <TextInformation>
                      Praça Santo Agostinho,70, Aclimação, São Paulo - Brasil |
                      18 Pininsula center - Roling Hills Estates, CA 90274 - USA
                    </TextInformation>
                  </Content>
                </ContactInformations>
              </OfficeAdress>
            </OfficeInformations>
            <Title style={{ fontSize: "18px", fontWeight: "500" }}>
              SOLICITE UM ATENDIMENTO AQUI
            </Title>
            <FormSendContact onSubmit={handleSubmit(onSubmit)}>
              <PrimaryFields>
                <ContentInput>
                <Input
                  {...register("name", { required: true })}
                  placeholder="Nome"
                  control={control}
                />
                {errors.name && errors.name.type === "required" && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Preenchimento Obrigatório.
                  </span>
                )}
                </ContentInput>
                <ContentInput>
                <Input
                  {...register("email", { required: true })}
                  placeholder="Email"
                  control={control}
                />
                {errors.email && errors.email.type === "required" && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Preenchimento Obrigatório.
                  </span>
                )}
                </ContentInput>
                
                <ContentInput>

                <Input
                  {...register("phone", { required: true })}
                  placeholder="Telefone"
                  control={control}
                />
                {errors.phone && errors.phone.type === "required" && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Preenchimento Obrigatório.
                  </span>
                )}
                </ContentInput>
              </PrimaryFields>
              
              <MessageContainer>
                <Input
                  {...register("message", { required: true })}
                  placeholder="Mensagem"
                  control={control}
                />
                {errors.message && errors.message.type === "required" && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Preenchimento Obrigatório.
                  </span>
                )}
                <ButtonForm type="submit">ENVIAR</ButtonForm>
              </MessageContainer>
            </FormSendContact>
          </InformationsContact>
        </ContactRealEstate>
      </ContentContact>
      <Footer />
    </Container>
  );
};

export default Contact;
